window.global ||= window;

// scroll to the first visible element matching the selector, with an optional margin on top.
window.scrollToElement = function (selector, top = 0) {
  const element = [...document.querySelectorAll(selector)].filter(el => el.offsetParent !== null)[0];
  if (element) {
    const elementPosition = element.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - top;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }
}